import React from 'react'
import { Link, graphql } from 'gatsby'
import Footer from '../components/footer'
import Seo from '../components/seo'
import Nav from '../components/nav'

const EntryPage = ({data}) => {
  const entry = data.markdownRemark
	return (
    <div>
      <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
      <Nav />
      <div className="w-full py-12 bg-blue">
        <div className="w-10/12 mx-auto max-w-screen-2xl">
          <div className="flex justify-around items-center text-center">
            <div className="w-8/12 lg:w-6/12 font-verdana">
              <h1 className="text-white font-bold text-3xl lg:text-5xl leading-tight">{entry.frontmatter.heading}</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pt-12 pb-12 border-b-2 border-grey-c">
        <div className="w-10/12 mx-auto max-w-screen-2xl">
          <div className="flex-none lg:flex justify-between gap-24">
            <div className="w-full lg:w-3/12">
              <div className="max-w-none font-verdana prose leading-relaxed">
                <div dangerouslySetInnerHTML={{ __html: entry.html }} />
              </div>
              <div className="font-verdana text-xl font-bold text-white mb-12 lg:mb-6 mt-6 bg-red py-3 px-5 inline-block">
                <Link to="/contact/">Enquire now</Link>
              </div>
            </div>
            <div className="w-full lg:w-9/12">
              <div className="grid grid-cols-1 lg:grid-cols-4 gap-12 text-center">
                <div>
                  <div className="w-full h-48 bg-[#BEC5C8]">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Abbey Grey</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-[#E2E3DF]">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Cloud</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-[#C9B79F]">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Desert Sand</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-[#303433]">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Ebony</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-[#3E1F18]">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Farmer Brown</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-[#2A4235]">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Forest Green</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-[#515658]">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Grey Fliar</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-[#BABFBC]">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Gull Grey</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-[#484945]">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Ironsand</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-[#34362A]">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Karaka</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-[#988F78]">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Lichen</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-[#717372]">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Mild Grey</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-[#848D76]">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Mist Green</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-[#545E65]">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">New Demin Blue</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-[#455F50]">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Permanent Green</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-[#864937]">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Pioneer Red</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-[#6A796A]">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">River Gum</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-[#6B443B]">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Scoria</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-[#3D4244]">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Slate</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-[#706B5F]">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Sorrell</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-[#AAA18C]">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Stone</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-[#AB755A]">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Terracota</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-[#595E56]">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Thunder Grey</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-[#DFDED1]">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Titania</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-[#454746]">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Tudor Grey</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-[#383C3B]">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Windsor Grey</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
	)
}

export default EntryPage;

export const query = graphql`
	query($slug: String!) {
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
        title
				page_title
				meta_description
				heading
        quote
        author
			}
			fields {
				slug
			}
      html
		}
	}
`